import React from "react"
import { Carousel } from "react-bootstrap"

const Testimonials = ({ title, testi, vid }) => {
  return (
    <div className="testimonials">
      <div className="banner-container">
        <div className="video-container2">
          <video loop muted autoPlay>
            <source src={vid} type="video/mp4" />
          </video>
        </div>
        <div className="details-container text-center">
          <div className="container">
            <h1>{title}</h1>
            <Carousel indicatorLabels={false} indicators={false}>
              {testi.map((test, i) => {
                return (
                  <Carousel.Item key={i}>
                    <p className="h6">{test.actual_testimonial}</p>
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
