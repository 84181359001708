import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Container } from "react-bootstrap"

const Cards = ({ cards }) => {
  return (
    <Container>
      <section className="cards">
        {cards.map(card => {
          const path = getImage(card.card_img?.localFile)
          return (
            <div className="card" key={card.id}>
              <div className="card-inner">
                <div className="card-front">
                  <GatsbyImage
                    image={path}
                    alt={card.card_heading}
                    className="c-img"
                  />
                </div>
                <div className="card-back">
                  <GatsbyImage
                    image={path}
                    alt={card.card_heading}
                    className="c-img"
                  />
                  <div className="details-container p-3">
                    <h1>{card.card_heading}</h1>
                    <p>{card?.card_description}</p>
                    <Link to={card?.card_button_link}>
                      <button className="btn btn-yellow">
                        {card?.card_button_text}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </section>
    </Container>
  )
}

export default Cards
