import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import VideoBanner from "../components/VideoBanner"
import { graphql } from "gatsby"
import Cards from "../components/Cards"
import Gallery from "../components/Gallery"
import Testimonials from "../components/Testimonials"
// import vid1 from "../assets/videos/vid1.mp4"

const Index = ({ data }) => {
  const { strapiHomePage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="Home" />
      <VideoBanner
        heading={info.banner_heading}
        para={info.banner_description}
        btn
        vid={info.banner_video.url}
      />
      <div className="card-section">
        <Cards cards={info.banner_cards} />
      </div>
      <Testimonials
        title={info.testimonial_title}
        testi={info.testimonials}
        vid={info.testimonial_video.url}
      />
      <h1 className="my-4 text-center">Photo Gallery</h1>
      <Gallery />
    </Layout>
  )
}

export const data = graphql`
  {
    strapiHomePage {
      banner_heading
      banner_description
      banner_video {
        url
      }
      testimonial_title
      testimonials {
        id
        actual_testimonial
      }
      testimonial_video {
        url
      }
      banner_cards {
        id
        card_heading
        card_description
        card_button_text
        card_button_link
        card_img {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`

export default Index
